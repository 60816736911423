<template>
	<div class="industry-dynamics">
		<div class="title-card">
			<div class="title-box">
				<div class="title">建筑行业动态</div>
				<div class="result">
					<span>共找到</span>
					<span class="num">{{ total }}</span>
					<span>条结果</span>
				</div>
			</div>
			<div class="search-box">
				<div class="search-form">
					<div class="form-item">
						<div class="form-lable">日期选择</div>
						<div class="date-picker">
							<el-date-picker v-model="conPO.dateStart" type="month" :picker-options="dateStart"
								:default-value="maxDate" value-format="yyyy-MM" placeholder="选择开始日期"></el-date-picker>
							<!-- <div class="iconfont-box">
                <span class="iconfont icon-riliriqi2"></span>
              </div> -->
						</div>
						<div class="line"></div>
						<div class="date-picker">
							<el-date-picker v-model="conPO.dateEnd" type="month" :picker-options="dateEnd"
								:default-value="maxDate" value-format="yyyy-MM" placeholder="选择结束日期"></el-date-picker>
							<!-- <div class="iconfont-box">
                <span class="iconfont icon-riliriqi2"></span>
              </div> -->
						</div>
						<div class="form-lable lable">期刊号</div>
						<div>
							<el-autocomplete v-model="conPO.serialCode" :fetch-suggestions="getIndustryDynamicSerialCode"
								:trigger-on-focus="false" placeholder="请输入期刊号" clearable></el-autocomplete>
						</div>
					</div>
				</div>
				<div class="search-button">
					<el-button type="primary" @click="handleQuery">筛选</el-button>
					<el-button @click="resetQuery">清空</el-button>
				</div>
			</div>
		</div>
		<div class="list-box">
			<div class="result" v-show="total != 0">搜索结果</div>
			<div class="result-box">
				<div class="empty-box" v-show="total == 0">
					<empty></empty>
				</div>
				<div class="list-item" v-for="(item, index) in pageIndustry" :key="index" @click="toDetail(item)">
					<div class="name-left">
						<div class="tag-icon"></div>
						<el-tooltip effect="light" :content="item.industryTitle" placement="bottom-start">
							<div class="name-box text-ellipsis">
								{{ item.industryTitle }}
							</div> 
						</el-tooltip>
						<collect :ab="true" v-model="item.isCollection" :itemId="item.industryDynamicId" type='行业动态' />
						<!-- <DownloadButton type="建筑行业动态" :id="item.industryDynamicId" :detailData="item"/> -->
					</div>
					<div class="code-bottom">
						<div>
							<div class="price-box">
								<div v-if="item.chargeType == '60451002'">
									价格：
									<span class="unit">￥</span>
									<span class="price">{{ item.downloadPrice }}</span>
									/期
								</div>
								<div v-if="item.chargeType == '60451001'">
									价格：
									<span class="unit"></span>
									<span style="color: #23ab82">免费</span>
								</div>
							</div>
							<div class="member" v-show="item.memberPrice >0">
								会员价格：
								<span style="color: #23ab82">
									<span class="unit">￥</span>
									{{ item.memberPrice }}
								</span>
							</div>
						</div>

						<div class="code-bottom">
							<div class="serial-code">{{ item.serialCode }}</div>
							<div class="date-right">{{ item.dispatchDate }}</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<div class="pagination-box">
			<pagination ref="pagination" :total="total" :key="paginationKey" @change="paginChange" v-show="total != 0">
			</pagination>
		</div>
		<login-modal ref="loginModal"></login-modal>
		<member-modal ref="memberModal"></member-modal>
		<ToPayOrMember ref="toPayOrMember" :showPayBtn="showPayBtn" :conPO="payData" type="行业动态"></ToPayOrMember>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import LoginModal from "@/components/loginModal";
import MemberModal from "@/components/MemberModal";
import ToPayOrMember from "@/components/ToPayOrMember";
import collect from "@/components/collect";
import DownloadButton from "@/components/downloadButton";
export default {
	components: {
		Pagination,
		Empty,
		LoginModal,
		MemberModal,
		ToPayOrMember,
		collect,
		DownloadButton
	},
	data() {
		return {
			paginationKey: "",
			total: 0, // 结果数量
			conPO: {
				dateStart: "",
				dateEnd: "",
				serialCode: "",
				platform: 1,
				timeSort: 1,
				pageSize: 10, //页数
				pageNum: 1, // 页码
			}, // 查询条件
			pageIndustry: [],
			minDate: '',
			maxDate: '',
			showPayBtn:false,
			payData: null,
		};
	},
	computed: {
		dateStart() {
			return {
				disabledDate: time => {
					let disableTime
					if (this.conPO.dateEnd) {
						disableTime = time.getTime() > new Date(this.conPO.dateEnd).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						disableTime = time > this.maxDate || time < this.minDate;
					}
					return disableTime
				},
			};
		},
		dateEnd() {
			return {
				disabledDate: time => {
					if (this.conPO.dateStart) {
						return time.getTime() < new Date(this.conPO.dateStart).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						return time > this.maxDate || time < this.minDate;
					}
				},
			};
		},
	},
	mounted() {
		this.getTimeSelector()
		// 数据埋点
		this.$api.home.buriedPoint({
			terminal: "60441001",
			consumerId: this.$session.getUsers() ? this.$session.getUsers().consumerId : "",
			positionCode: "604410011009",
		});
		this.getIndustryDynamicPage();
	},
	methods: {
		getTimeSelector() {
			this.$api.industry.getTimeSelectorIndustry().then(res => {
				this.minDate = new Date(res.data.minDateTime + ' 00:00:00');
				this.maxDate = new Date(res.data.maxDateTime + ` 23:59:59`);
			})
		},
		// 期刊号联想搜索
		getIndustryDynamicSerialCode(queryString, callback) {
			this.$api.industry
				.getIndustryDynamicSerialCode({
					serialCode: queryString,
				})
				.then(res => {
					let newData = [];
					res.data.forEach(item => {
						let obj = {
							value: item,
						};
						newData.push(obj);
					});
					callback(newData);
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 列表数据查询
		getIndustryDynamicPage() {
			const loading = this.$loading({
				lock: true,
				text: "加载中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.industry
				.getIndustryDynamicPage(this.conPO)
				.then(res => {
					res.rows.forEach(item => {
						if (item.dispatchDate) {
							item.dispatchDate = item.dispatchDate.slice(0, 7);
						}
					});
					this.pageIndustry = res.rows;
					this.total = res.total;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 点击筛选
		handleQuery() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.$refs.pagination.page = 1;
				this.conPO.pageNum = 1;
				this.conPO.pageSize = 10;
				this.getIndustryDynamicPage();
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 点击清空
		resetQuery() {
			this.conPO = {
				dateStart: "",
				dateEnd: "",
				serialCode: "",
				platform: 1,
				pageSize: 10,
				pageNum: 1,
			};
		},
		// 进入详情
		toDetail(item) {
			this.payData=item
			let { industryDynamicId } = item;
			if (item.url) {
				this.$confirm("您即将跳转进入第三方网站，慧决策平台不对该网站内容及真实性负责，请注意您的账号和财产安全", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					window.open(item.url, "_blank");
				});
			} else {
				// 判断用户是否登录
				// let url = this.$router.resolve({
				// 	path: `/industry/industry-detail?type=1&id=${item.industryDynamicId}&fileId=${item.fileId}`,
				// });
				let url ={
					href:`#/industry/industry-detail?type=1&id=${item.industryDynamicId}&fileId=${item.fileId}`
				}
				console.log('url',url);
				// let times = this.allRead - this.hasRead
				if (this.$session.getUsers()) {
					// 判断阅读，下载及会员情况
					this.$api.industry.getReadAndDownload({
						industryDynamicId:item.industryDynamicId,
					})
					.then(res => {
						let data = res.data;
						if (data.read == "10011001") {
							// 有阅读权限
							if (data.isRead) { 
								window.open(url.href, "_blank");
							} else {
								let params = {
									moduleCode: '1003',
									childModuleCode: '10031003',
									type: 2
								};
								this.$api.industry.getMemberPermission(params).then(res => {
									if (res.data != 0 && res.data > 0) {
										this.open(res.data, url);
									} else if (res.data < 0) { 
										window.open(url.href, "_blank");
									} else {
										this.text = "您的次数已用完，升级VIP会员"; 
										this.$refs.toPayOrMember.openDialog();
									}
								});
							}
						} else {
							// 没有阅读权限
							this.$refs.toPayOrMember.orderData = {industryDynamicId:industryDynamicId,readOrDownLoad:'60491001'};
							this.$refs.toPayOrMember.openDialog();
							this.showPayBtn = true;
						}
					})
					.catch(msg => {
						if (msg?.msg) {
							this.$message.error(msg?.msg);
						}
					});
					// this.$api.industry
					// 	.getIndustryDynamicInfoApp({
					// 		industryDynamicId: item.industryDynamicId,
					// 		platform: 1,
					// 	})
					// 	.then(res => {
					// 		let isLimit = res.data;
					// 		if (isLimit) {
					// 			window.open(url.href, "_blank");
					// 		} else {
					// 			this.$refs.toPayOrMember.openDialog();
					// 		}
					// 	});
				} else {
					this.$refs.loginModal.visible = true;
				}
			}
		},
		open(tiems, url) {
			this.$confirm(`您还剩余${tiems}次查阅机会，是否消耗1次？`, "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => { 
					setTimeout(() => { 
						window.open(url.href, "_blank");
					}, 200);
				})
				.catch(() => {
					// this.$message({
					// 	type: "info",
					// 	message: "已取消",
					// });
				});
		},
		// 分页方法
		paginChange(page, pageSize) {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.conPO.pageNum = page;
				this.conPO.pageSize = pageSize;
				this.getIndustryDynamicPage();
			} else {
				this.paginationKey = new Date().getTime();
				this.$refs.loginModal.visible = true;
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
